import React from 'react';
import { css, cx } from 'emotion';
import { Icon, stylesFactory } from '@grafana/ui';

import { RecommendationModel } from '../model/Recommendation.model';
import { YesNoComponent } from './YesNoComponent';

const phoneIcon = require('../img/phone_icon.png');
const arGlasIcon = require('../img/ar_glas.png');

interface Props {
  width: number;
  recommendation?: RecommendationModel;
}

export const Recommendation = ({ width, recommendation }: Props) => {
  //const usePhoneLayout = width < 400;
  const styles = getStyles();

  return (
    <div>
      <h2>{recommendation?.errorTitle}</h2>
      <h2
        className={cx(css`
          color: ${recommendation?.messageColor};
        `)}
      >
        {recommendation?.errorMessage}
      </h2>
      <div
        className={cx(
          css`
            display: flex;
            column-gap: 80px;
            row-gap: 20px;
            flex-direction: 'row';
            margin: ${recommendation?.serviceHotlinePhone || recommendation?.arVideoCallLink ? '50px 0' : '20px 0'};
          `
        )}
      >
        {recommendation?.serviceHotlinePhone && (
          <div>
            <h4>Servicehotline</h4>
            <img src={phoneIcon} height="40px"></img>
            T: {recommendation?.serviceHotlinePhone}
          </div>
        )}
        {recommendation?.arVideoCallLink && (
          <div>
            <h4>AR-Service (Live Bild)</h4>
            <img src={arGlasIcon} height="40px"></img>
            <a href={recommendation?.arVideoCallLink}>AR starten</a>
          </div>
        )}
      </div>
      <table>
        <thead>
          <tr className={styles.tableHeaderCell}>
            <th>Mögliche Ursachen</th>
            <th>Lösungsansatz</th>
            <th>Handlungsempfehlung</th>
            <th>Handlungsempfehlung ausgeführt</th>
            <th>Handlungsempfehlung wirksam</th>
          </tr>
        </thead>
        <tbody className={styles.tableBody}>
          {recommendation?.recommendationItems?.map((recommendationItem, index) => (
            <tr key={index} className={styles.tableRowCell}>
              <td>{recommendationItem.reason}</td>
              <td>{recommendationItem.solution}</td>
              <td>
                {recommendationItem.recommendationPDF && (
                  <a
                    href={recommendationItem.recommendationPDF}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.mr20}
                  >
                    <Icon name="file-alt"></Icon>
                    PDF
                  </a>
                )}
                {recommendationItem.recommendationVideo && (
                  <a
                    href={recommendationItem.recommendationVideo}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.mr20}
                  >
                    <Icon name="play"></Icon>
                    Video
                  </a>
                )}
              </td>
              <td>
                <YesNoComponent
                  isYes={recommendationItem.recommendationExecuted}
                  onCheckBoxValueChanged={(val: boolean) => (recommendationItem.recommendationExecuted = val)}
                ></YesNoComponent>
              </td>
              <td>
                <YesNoComponent
                  isYes={recommendationItem.recommendationWasEffective}
                  onCheckBoxValueChanged={(val: boolean) => (recommendationItem.recommendationWasEffective = val)}
                ></YesNoComponent>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

Recommendation.defaultProps = {
  messageColor: '#fff',
};

const getStyles = stylesFactory(() => {
  return {
    mr20: css`
      margin-right: 20px;
    `,
    tableHeaderCell: css`
      th {
        border: 1px solid black;
        padding: 10px 30px 10px 10px;
      }
    `,
    tableRowCell: css`
      td {
        border: 1px solid black;
        padding: 10px 30px 10px 10px;
      }
    `,
    tableBody: css`
      tr:nth-child(odd) td {
        background-color: #4b4b4b;
      }
    `,
  };
});
