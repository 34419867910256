import { PanelPlugin } from '@grafana/data';
import { SimpleOptions } from './types';
import { TrafficLightPanel } from './components/TrafficLightPanel';

export const plugin = new PanelPlugin<SimpleOptions>(TrafficLightPanel).setPanelOptions((builder) => {
  return builder
    .addColorPicker({
      path: 'greenColor',
      name: 'Green color',
      description: 'Green color of the traffic light',
      defaultValue: '#7ED321',
      settings: { allowUndefined: false, enableNamedColors: false },
    })
    .addColorPicker({
      path: 'yellowColor',
      name: 'Warning color',
      description: 'Warning color of the traffic light',
      defaultValue: '#F8E71C',
      settings: { allowUndefined: false, enableNamedColors: false },
    })
    .addColorPicker({
      path: 'redColor',
      name: 'Error color',
      description: 'Error color of the traffic light',
      defaultValue: '#D0021B',
      settings: { allowUndefined: false, enableNamedColors: false },
    })
    .addTextInput({
      path: 'statusQueryName',
      name: 'Wert Ampelstatus',
      description: 'Name der Query',
      defaultValue: '',
    })
    .addNumberInput({
      path: 'statusThresholdWarning',
      name: 'Warning threshold',
      defaultValue: 50,
    })
    .addNumberInput({
      path: 'statusThresholdError',
      name: 'Error threshold',
      defaultValue: 100,
    })
    .addBooleanSwitch({
      path: 'showInfo',
      defaultValue: false,
      name: 'Infofenster anzeigen',
    })
    .addRadio({
      path: 'position',
      name: 'Position',
      defaultValue: 'center',
      settings: {
        options: [
          {
            value: 'center',
            label: 'Center',
          },
          {
            value: 'top-left',
            label: 'Top left',
          },
          {
            value: 'top-right',
            label: 'Top right',
          },
          {
            value: 'bottom-left',
            label: 'Bottom left',
          },
          {
            value: 'bottom-right',
            label: 'Bottom right',
          },
        ],
      },
    })
    .addRadio({
      path: 'modalType',
      name: 'Modal Typ',
      defaultValue: 'info',
      settings: {
        options: [
          {
            value: 'info',
            label: 'Info',
          },
          {
            value: 'recommendation',
            label: 'Handlungsempfehlung',
          },
        ],
      },
    })
    .addTextInput({
      path: 'issueQueryName',
      name: 'Event Beschreibung (QueryName)',
      description: 'Name der Query',
      defaultValue: '',
      showIf: (currentOptions, data) => currentOptions.modalType === 'recommendation',
    })
    .addTextInput({
      path: 'issueColumn',
      name: 'Event (Spaltenname)',
      description: 'Name der Spalte',
      showIf: (currentOptions, data) => currentOptions.modalType === 'recommendation',
    })
    .addTextInput({
      path: 'issueDescriptionColumn',
      name: 'Event Beschreibung (Spaltenname)',
      description: 'Name der Spalte',
      showIf: (currentOptions, data) => currentOptions.modalType === 'recommendation',
    })
    .addTextInput({
      path: 'recommendationPossibleIssueQueryName',
      name: 'Handlungsempfehlung (QueryName)',
      description: 'Name der Query',
      defaultValue: '',
      showIf: (currentOptions, data) => currentOptions.modalType === 'recommendation',
    })
    .addTextInput({
      path: 'recommendationPossibleIssueColumn',
      name: 'Handlungsempfehlung Mögliche Ursache (Spaltenname)',
      description: 'Name der Spalte',
      showIf: (currentOptions, data) => currentOptions.modalType === 'recommendation',
    })
    .addTextInput({
      path: 'recommendationPossibleSolutionColumn',
      name: 'Handlungsempfehlung Lösungsansatz (Spaltenname)',
      description: 'Name der Spalte',
      showIf: (currentOptions, data) => currentOptions.modalType === 'recommendation',
    })
    .addTextInput({
      path: 'recommendationPossibleSolutionPDFColumn',
      name: 'Handlungsempfehlung PDF URL (Spaltenname)',
      description: 'Name der Spalte',
      showIf: (currentOptions, data) => currentOptions.modalType === 'recommendation',
    })
    .addTextInput({
      path: 'recommendationPossibleSolutionVideoColumn',
      name: 'Handlungsempfehlung Video URL (Spaltenname)',
      description: 'Name der Spalte',
      showIf: (currentOptions, data) => currentOptions.modalType === 'recommendation',
    })
    .addTextInput({
      path: 'serviceHotline',
      name: 'Service-Hotline',
      description: 'Telefonnummer',
      defaultValue: '',
      showIf: (currentOptions, data) => currentOptions.modalType === 'recommendation',
    })
    .addTextInput({
      path: 'arUrl',
      name: 'AR-URL',
      description: 'URL zum Starten der AR-Session',
      defaultValue: '',
      showIf: (currentOptions, data) => currentOptions.modalType === 'recommendation',
    });
});
