import React, { useEffect, useState } from 'react';
import { css, cx } from 'emotion';
import { stylesFactory, Modal, IconButton, Alert, Label } from '@grafana/ui';
import { Recommendation } from './Recommendation';
import { RecommendationModel } from '../model/Recommendation.model';

interface Props {
  width: number;
  redOn: boolean;
  yellowOn: boolean;
  greenOn: boolean;
  redColor: string;
  yellowColor: string;
  greenColor: string;
  showInfo: boolean;
  modalType: 'info' | 'recommendation';
  infoDialogTitle: string;
  recommendation?: RecommendationModel;
}

export const TrafficLight = ({
  width,
  redOn,
  yellowOn,
  greenOn,
  redColor,
  yellowColor,
  greenColor,
  showInfo,
  modalType,
  infoDialogTitle,
  recommendation,
  ...props
}: Props) => {
  const disabledColor = '#C6C5C5';
  const styles = getStyles();

  const usePhoneLayout = width < 400;

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [currentRecommendation, setCurrentRecommendation] = useState<RecommendationModel | undefined>(recommendation);

  useEffect(() => {
    setCurrentRecommendation(recommendation);
  }, [recommendation]);

  const closeModal = () => setModalIsOpen(false);
  const openModal = () => setModalIsOpen(true);

  return (
    <div className={styles.container}>
      <div className={styles.circleContainer}>
        {!usePhoneLayout && <Label className={styles.header}>{yellowOn ? 'Aktion' : redOn ? 'Störung' : 'OK'}</Label>}
        <div
          onClick={openModal}
          className={cx(
            styles.circle,
            css`
              background-color: ${greenOn ? greenColor : disabledColor};
              opacity: ${greenOn ? 1.0 : 0.2};
            `
          )}
        />
        <div
          onClick={openModal}
          className={cx(
            styles.circle,
            css`
              background-color: ${yellowOn ? yellowColor : disabledColor};
              opacity: ${yellowOn ? 1.0 : 0.2};
            `
          )}
        />
        <div
          onClick={openModal}
          className={cx(
            styles.circle,
            css`
              background-color: ${redOn ? redColor : disabledColor};
              opacity: ${redOn ? 1.0 : 0.2};
            `
          )}
        />
      </div>
      {showInfo && (
        <>
          <IconButton
            className={styles.icon}
            name={modalType === 'recommendation' ? 'comment-alt' : 'info-circle'}
            onClick={() => setModalIsOpen(!modalIsOpen)}
            size="lg"
          />
          {modalType === 'info' && (
            <Modal title={infoDialogTitle} isOpen={modalIsOpen} onDismiss={closeModal}>
              <Alert title="Alles OK" severity="success" />
              <Alert title="Antrieb defekt" severity="warning" />
              <Alert title="Verschleißgrad erreicht" severity="error" />
            </Modal>
          )}
          {modalType === 'recommendation' && (
            <Modal
              title={infoDialogTitle}
              isOpen={modalIsOpen}
              onDismiss={closeModal}
              className={cx(
                css`
                  width: 70%;
                  max-width: 1400px;
                `
              )}
            >
              {currentRecommendation && (
                <Recommendation width={width} recommendation={currentRecommendation}></Recommendation>
              )}
            </Modal>
          )}
        </>
      )}
    </div>
  );
};

TrafficLight.defaultProps = {
  redOn: false,
  yellowOn: false,
  greenOn: false,
  redColor: '#E7302A',
  yellowColor: '#FBD127',
  greenColor: '#69B01B',
  showInfo: false,
  modalType: 'info',
};

const getStyles = stylesFactory(() => {
  return {
    container: css`
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 40px;
    `,
    circleContainer: css`
      display: flex;
      margin-right: 10px;
      margin-left: 10px;
    `,
    circle: css`
      width: 20px;
      height: 20px;
      margin-left: 3px;
      border-radius: 50%;
    `,
    header: css`
      margin-right: 5px;
      margin-bottom: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
    `,
    icon: css`
      display: inline-block;
      justify-content: center;
      align-items: center;
    `,
  };
});
