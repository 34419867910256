import React, { useEffect, useState } from 'react';
import { PanelProps } from '@grafana/data';
import { css, cx } from 'emotion';
import { getTheme, stylesFactory } from '@grafana/ui';
import { hackToString, getNumberFromQuery, getSingleValueFromQuery, getValuesFromQuery } from 'uef-common';

import { RecommendationListItemModel, RecommendationModel } from '../model/Recommendation.model';
import { TrafficLight } from './TrafficLight';
import { SimpleOptions } from '../types';

interface Props extends PanelProps<SimpleOptions> {}

const hasRecommendationsConfigured = (options: SimpleOptions) => {
  return (
    options.issueQueryName &&
    options.issueColumn &&
    options.issueDescriptionColumn &&
    options.recommendationPossibleIssueColumn &&
    options.recommendationPossibleIssueQueryName &&
    options.recommendationPossibleSolutionColumn
  );
};

export const TrafficLightPanel: React.FC<Props> = ({ options, data, width, height }) => {
  const styles = getStyles();
  const theme = getTheme();
  const [currentRecommendations, setCurrentRecommendations] = useState<RecommendationModel | undefined>();
  const [statusValue, setStatusValue] = useState<number>(0);
  const [greenOn, setGreenOn] = useState<boolean>(false);
  const [yellowOn, setYellowOn] = useState<boolean>(false);
  const [redOn, setRedOn] = useState<boolean>(false);

  useEffect(() => {
    setGreenOn(statusValue < options.statusThresholdWarning);
    setYellowOn(statusValue >= options.statusThresholdWarning && statusValue < options.statusThresholdError);
    setRedOn(statusValue >= options.statusThresholdError);
  }, [statusValue, options]);

  useEffect(() => {
    if (hasRecommendationsConfigured(options)) {
      setCurrentRecommendations(() => {
        const issue = getSingleValueFromQuery(data, options.issueQueryName, options.issueColumn);
        const issueDescription = getSingleValueFromQuery(data, options.issueQueryName, options.issueDescriptionColumn);
        const possibleIssues = getValuesFromQuery(
          data,
          options.recommendationPossibleIssueQueryName,
          options.recommendationPossibleIssueColumn
        );
        const solutions = getValuesFromQuery(
          data,
          options.recommendationPossibleIssueQueryName,
          options.recommendationPossibleSolutionColumn
        );
        const pdfUrls = getValuesFromQuery(
          data,
          options.recommendationPossibleIssueQueryName,
          options.recommendationPossibleSolutionPDFColumn
        );
        const videoUrls = getValuesFromQuery(
          data,
          options.recommendationPossibleIssueQueryName,
          options.recommendationPossibleSolutionVideoColumn
        );

        const recommendations: RecommendationListItemModel[] = [];
        for (let i = 0; i < possibleIssues.length; i++) {
          recommendations.push({
            reason: hackToString(possibleIssues[i]),
            solution: hackToString(solutions[i]),
            recommendationPDF: hackToString(pdfUrls[i]),
            recommendationVideo: hackToString(videoUrls[i]),
            recommendationExecuted: false,
            recommendationWasEffective: false,
          });
        }

        const recommendation: RecommendationModel = {
          arVideoCallLink: options.arUrl ?? '',
          serviceHotlinePhone: options.serviceHotline ?? '',
          errorTitle: issue ?? '',
          errorMessage: issueDescription ?? '',
          messageColor: 'red',
          recommendationItems: recommendations,
        };
        return recommendation;
      });
    } else {
      setCurrentRecommendations(undefined);
    }

    // update traffic light
    let newStatusValue = 0;
    if (options.statusQueryName) {
      newStatusValue = getNumberFromQuery(data, options.statusQueryName);
    }
    setStatusValue(newStatusValue);
  }, [data, options, width]);

  // default position is center
  const { position } = options;
  let justifyContent = 'center';
  let alignItems = 'center';

  // resolve position
  justifyContent = position.match(/top/gi) ? 'flex-start' : justifyContent;
  justifyContent = position.match(/bottom/gi) ? 'flex-end' : justifyContent;
  alignItems = position.match(/left/gi) ? 'start' : alignItems;
  alignItems = position.match(/right/gi) ? 'end' : alignItems;

  return (
    <div
      className={cx(
        styles.wrapper,
        css`
          width: ${width}px;
          height: ${height}px;
          align-items: ${alignItems};
          justify-content: ${justifyContent};
        `
      )}
    >
      <div
        className={cx(
          css`
            ${position.match(/top/gi) ? 'top: -14px' : ''};
            ${position.match(/bottom/gi) ? 'bottom: 0px' : ''};
            ${position.match(/left/gi) ? 'left: 0px' : ''};
            ${position.match(/right/gi) ? 'right: 0px' : ''};
            position: absolute;
          `
        )}
      >
        <TrafficLight
          width={width}
          greenOn={greenOn}
          yellowOn={yellowOn}
          redOn={redOn}
          greenColor={theme.visualization.getColorByName(options.greenColor)}
          yellowColor={theme.visualization.getColorByName(options.yellowColor)}
          redColor={theme.visualization.getColorByName(options.redColor)}
          showInfo={options.showInfo}
          infoDialogTitle={options.infoDialogTitle ?? ''}
          modalType={options.modalType}
          recommendation={currentRecommendations}
        />
      </div>
    </div>
  );
};

const getStyles = stylesFactory(() => {
  return {
    wrapper: css`
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    `,
  };
});
