import React from 'react';
import { css, cx } from 'emotion';
import { Checkbox } from '@grafana/ui';

interface Props {
  isYes: boolean;
  onCheckBoxValueChanged?: (value: boolean) => void;
}

export const YesNoComponent = ({ isYes }: Props) => {
  const [isChecked, setIsChecked] = React.useState(isYes);

  const onCheckBoxChanged = (newValue: boolean) => {
    setIsChecked(newValue);
  };

  return (
    <span>
      <Checkbox
        value={isChecked}
        label="ja"
        onChange={() => onCheckBoxChanged(true)}
        className={cx(
          css`
            margin-right: 20px;
          `
        )}
      ></Checkbox>
      <Checkbox value={!isChecked} label="nein" onChange={() => onCheckBoxChanged(false)}></Checkbox>
    </span>
  );
};
